// apiService.js
import axios from "axios";
const baseURL =
  // process.env.REACT_APP_BASE_URL; // replace with your API base URL
  "https://dev.api.assuredpay.in";
const apiService = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    // Add any additional headers if needed
  },
});

const ApiService = {
  getUserCoordinates: () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords, timestamp } = position;
          const LocationInfoData = {
            timestamp: timestamp,
            latitude: coords.latitude,
            longitude: coords.longitude,
            accuracy: coords.accuracy,
          };
          resolve(LocationInfoData);
          return LocationInfoData;
        },
        (error) => {
          console.log(error, "Error from geolocation API");
          alert(
            "Please Enable the location Access, if moving forward with this app"
          );
          reject(error);
          return error;
        }
      );
    });
  },

  setToken: (token: any) => {
    // Set the Authorization header for all requests
    apiService.defaults.headers.common["token"] = `${token}`;
    // apiService.defaults.headers.common['Authorizations'] = `Bearer ${token}`;
  },

  // Function to make a GET request with query parameters
  get: async (endpoint: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.get(endpoint, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  // Function to make a POST request with query parameters
  post: async (endpoint: any, data: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.post(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  // Function to make a PUT request with query parameters
  put: async (endpoint: any, data: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.put(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  // Function to make a PUT request with query parameters
  patch: async (endpoint: any, data: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    console.log("userCoordinates", userCoordinates);
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.patch(endpoint, data, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },

  // Function to make a DELETE request with query parameters
  delete: async (endpoint: any, params = {}) => {
    const userCoordinates: any = await ApiService.getUserCoordinates();
    apiService.defaults.headers.common["lat"] = userCoordinates.latitude;
    apiService.defaults.headers.common["lng"] = userCoordinates.longitude;
    try {
      const response = await apiService.delete(endpoint, { params });
      return response;
    } catch (error) {
      let response = error;
      return response;
    }
  },
};

// // Make a POST request with query parameters
// const postData = { key1: 'value1', key2: 'value2' };
// const postResponse = await ApiService.post('/your-endpoint', postData, { param1: 'value1' });

// // Make a PUT request with query parameters
// const putData = { key1: 'value1', key2: 'value2' };
// const putResponse = await ApiService.put('/your-endpoint', putData, { param1: 'value1' });

// // Make a DELETE request with query parameters
// const deleteResponse = await ApiService.delete('/your-endpoint', { param1: 'value1' });

// Api Response regarding then and catch method
// get: async (endpoint: any, params = {}) => {
//   return await apiService
//     .get(endpoint, { params })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error;
//     });
// },

export default ApiService;
